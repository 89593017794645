import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as allCameraListZoneActions from './actions'
import * as violationDetailActions from '../ViolationDetailPage/actions'
import * as NotificationActions from '../NotificationSidebar/actions'

import fetchErrorHandler from '../../utils/fetchErrorHandler'
import ListBoardWrapper from '../../components/ListBoardWrapper/Loadable'
import Dropdown from '../../components/Dropdown/Loadable'
import CameraZoneCard from '../../components/CameraZoneCard/Loadable'
import SkeletonLoading from '../../components/SkeletonLoading/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'

import AllCameraZoneStyled from './styledComponent'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_FAILURE,
  FRONTEND_ROUTES,
  THAI_LANGUAGE,
  SORT_TYPE_TITLE,
  SORT_TYPE_CAMERA_ID,
  SORT_TYPE_CAMERA_STATUS
} from '../../utils'
import MESSAGE from './message'

let timing

export class AllCameraZonePage extends React.Component {
  state = {
    cameraZoneListFilterBy: { text: this.getMessage('default_option'), value: '' },
    showAlertNoImageModal: false,
    modalData: {
      title: '',
      message: '',
      onOverLayClose: null
    },
    failureModalData: {
      show: false,
      title: '',
      message: '',
      onPrimaryButtonClick: undefined,
      primaryButtonText: ''
    }
  }

  componentDidMount() {
    this._loadCameraZoneList()
    this.initialIntervalLoadData()
  }

  componentWillUnmount() {
    clearInterval(timing)
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleFetchLoadCameraZoneList(prevProps)
  }

  handleFetchLoadCameraZoneList(prevProps) {
    if (prevProps.loadListZoneStatus === FETCH_STATUS_REQUEST && this.props.loadListZoneStatus === FETCH_STATUS_FAILURE) {
      this.handleLoadCameraZoneListFailure()
    }
  }

  handleLoadCameraZoneListFailure() {
    const statusCode = this.props.loadListZoneError.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  initialIntervalLoadData() {
    timing = setInterval(() => {
      this.syncData()
    }, process.env.INTERVAL_CALL_API_MINUTES * 60 * 1000)
  }

  syncData() {
    const siteId = this.props.match.params.siteId
    this.props.syncCameraList(siteId)
    this.props.syncCameraZoneList(siteId)
    this.props.getListNotification(siteId)
  }

  forceLogin() {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  _loadCameraZoneList() {
    const siteId = this.props.match.params.siteId
    this.props.loadCameraZoneList(siteId)
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language]
    return msg[property]
  }

  handleTypeChange(type) {
    switch (type) {
      case SORT_TYPE_TITLE:
        this.handleSortByTitle()
        break
      case SORT_TYPE_CAMERA_ID:
        this.handleSortByCameraID()
        break
      case SORT_TYPE_CAMERA_STATUS:
        this.handleSortByCameraStatus()
        break
      default:
    }
  }

  handleSortByTitle() {
    const compare = (a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    }
    this.props.cameraZoneList.sort(compare)
  }

  handleSortByCameraID() {
    const compare = (a, b) => {
      if (a.camera_id < b.camera_id) {
        return -1
      }
      if (a.camera_id > b.camera_id) {
        return 1
      }
      return 0
    }
    this.props.cameraZoneList.sort(compare)
  }

  handleSortByCameraStatus() {
    const compare = (a, b) => {
      if (a.camera_status.toUpperCase() < b.camera_status.toUpperCase()) {
        return -1
      }
      if (a.camera_status.toUpperCase() > b.camera_status.toUpperCase()) {
        return 1
      }
      return 0
    }
    this.props.cameraZoneList.sort(compare)
  }

  cameraZoneListFilterByChange = (type) => {
    this.setState({
      cameraZoneListFilterBy: type
    })
    this.handleTypeChange(type.value)
  }

  hideModal() {
    this.setState({
      showAlertNoImageModal: false,
      modalData: {
        title: '',
        message: '',
        onOverLayClose: undefined
      }
    })
  }

  handleOnEditRuleCLick(siteId, cameraId, cameraZone) {
    const projectID = this.props.match.params.projectID
    if (!cameraZone.image) {
      this.setState({
        showAlertNoImageModal: true,
        modalData: {
          title: this.getMessage('no_image_title'),
          message: this.getMessage('no_image_message'),
          onOverLayClose: () => this.hideModal()
        }
      })
    } else {
      this.props.history.push(`${FRONTEND_ROUTES.selectSiteRoute}/${projectID}/${siteId}/zone/${cameraId}`)
    }
  }

  renderModal() {
    let output = null
    if (this.state.showAlertNoImageModal) {
      const { modalData } = this.state
      output = (
        <ModalNotification
          className={'camera-no-img-modal'}
          title={modalData.title}
          message={modalData.message}
          onOverLayClose={modalData.onOverLayClose}
        />
      )
    }
    return output
  }

  getFilters() {
    const dropdownOptions = [
      { text: this.getMessage('default_option'), value: '' },
      { text: this.getMessage('camera_id_option'), value: SORT_TYPE_CAMERA_ID },
      { text: this.getMessage('camera_title_option'), value: SORT_TYPE_TITLE },
      { text: this.getMessage('camera_status_option'), value: SORT_TYPE_CAMERA_STATUS }
    ]

    return (
      <div className="filter-wrapper">
        <div className="dropdown-container">
          <Dropdown
            options={dropdownOptions}
            label={this.getMessage('sort_by_label')}
            onChange={this.cameraZoneListFilterByChange}
            value={this.state.cameraZoneListFilterBy}
            labelPosition="left"
            buttonColor="#1f2d39"
            language={this.props.currentUser?.language || THAI_LANGUAGE}
          />
        </div>
      </div>
    )
  }

  getContent() {
    let output = <SkeletonLoading className="loading" />
    if (this.props.cameraZoneList.length > 0) {
      output = this.props.cameraZoneList.map((cameraZone) => {
        const isAdmin = this.props.currentUser ? this.props.currentUser.admin : false
        const isManager = this.props.currentUser ? this.props.currentUser.manager : false
        const isUser = !(isAdmin || isManager)
        return (
          <CameraZoneCard
            key={`cameraId-${cameraZone.camera_id}`}
            data={cameraZone}
            language={this.props.currentUser?.language || THAI_LANGUAGE}
            onEditRuleClick={() => this.handleOnEditRuleCLick(cameraZone.site_id, cameraZone.camera_id, cameraZone)}
            isUser={isUser}
          />
        )
      })
    }
    return <div className="zone-list-wrapper">{output}</div>
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className="default-failure-modal"
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <AllCameraZoneStyled>
        <ListBoardWrapper title={this.getMessage('zone_setting_header')} filter={this.getFilters()}>
          {this.getContent()}
        </ListBoardWrapper>
        {this.renderModal()}
        {this.getFailureModal()}
      </AllCameraZoneStyled>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    currentUser: state.loginPage.loginAPI.userData,
    cameraZoneList: state.allCameraZonePage.cameraZoneList,
    loadListZoneStatus: state.allCameraZonePage.loadListZone.fetchStatus,
    loadListZoneError: state.allCameraZonePage.loadListZone.error
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, allCameraListZoneActions, violationDetailActions, NotificationActions), dispatch)
}

AllCameraZonePage.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    language: PropTypes.string
  }),
  cameraZoneList: PropTypes.arrayOf(
    PropTypes.shape({
      area_id: PropTypes.number,
      area_name: PropTypes.string,
      camera_id: PropTypes.number,
      camera_status: PropTypes.string,
      image: PropTypes.string,
      is_active: PropTypes.bool,
      project_id: PropTypes.number,
      resolution_width: PropTypes.number,
      resolution_height: PropTypes.number
    })
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCameraZonePage)
