const MESSAGE = {
  EN: {
    zone_setting_header: 'Camera List',
    default_option: '-- Select Sort By --',
    camera_title_option: 'Title',
    camera_id_option: 'Camera ID',
    camera_status_option: 'Status',
    sort_by_label: 'Sort by',
    title_subheader: 'TITLE',
    camera_id_subheader: 'CAMERA ID',
    zone_subheader: 'ZONE',
    status_subheader: 'STATUS',
    edit_rule_button: 'Edit Rules',
    edit_button: 'Edit',
    cancel_button: 'Cancel',
    no_image_title: 'No Image',
    no_image_message: 'There is no image. Please check camera connection.',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again'
  },
  TH: {
    zone_setting_header: 'รายชื่อกล้อง',
    default_option: '-- เลือกการจัดเรียง --',
    camera_title_option: 'ชื่อกล้อง',
    camera_id_option: 'ID กล้อง',
    camera_status_option: 'สถานะ',
    sort_by_label: 'จัดเรียงตาม',
    title_subheader: 'ชื่อกล้อง',
    camera_id_subheader: 'ID กล้อง',
    zone_subheader: 'โซน',
    status_subheader: 'สถานะ',
    edit_rule_button: 'แก้ไขกฎ',
    edit_button: 'แก้ไข',
    cancel_button: 'ยกเลิก',
    no_image_title: 'ไม่มีภาพ',
    no_image_message: 'ไม่มีภาพ กรุณาตรวจสอบการเชื่อมต่อของกล้อง',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง'
  }
}

export default MESSAGE
